function autoSelect () {
     const targets = document.querySelectorAll(".auto-select");
     targets.forEach(target => {
          $(target).append('<i class="fa fa-times" aria-hidden="true"></i>')
          $(target).append(view('partials/auto-complate-select'));
          $(target).find(".auto-compate-select, select").hide();
          $(target).find("p").text($(target).find("select option").attr("title"));

          if($(target).find("select").attr("disabled")) {
               $(target).addClass("disabled");
          }
          $(target).on("click", (e) => $(target).find(".auto-compate-select").show());
          $(target).find("input.auto-select-search").on('input',(e) => search(e, $(target).find("select"), $(target)));
          $(target).find("i").on("click", () => $(target).find("p").text(""));
          close(target);
     });

     const search = (e, select, element) => {
          const url = $(select).data("url");
          if(e.target.value.length >= 3) {
               ajax({
                    url: dataActive.location.marketplace + url + "?q=" + e.target.value,
                    success: function(data) {
                         if(data.data == 0) {
                              element.find(".auto-compate-select div").show().text(trans("No results found"))
                         }
                         else {
                              makeList(data.data, element, select);
                              element.find(".auto-compate-select div").hide();
                         }
                    }
               })
          }
          else {
               element.find(".auto-compate-select ul").empty();
          }
     }

     const makeList = (data, element, select) => {
          element.find(".auto-compate-select ul").empty();
          data.forEach(city => {
              const $row = $(`<li data-id="${city.id}">${city.title}</li>`);
              element.find(".auto-compate-select ul").append($row);
               $row.click ((e) => {
                    e.stopPropagation();
                    $(select).parent().find("p").text(city.title);
                    $(select).empty().append(`<option value="${city.id}">${city.title}</option>`);
                    $(select).change();
                    element.find(".auto-compate-select").hide();
                    element.find(".auto-compate-select ul").empty();
                    element.find("input.auto-select-search").val("")
               })
          })
     }

     function close(element) {
          $("html").on("click", (e) => {
               if(!$(element).find(e.target).length) {
                    $(element).find(".auto-compate-select").hide();
               }
          })
     }
}
